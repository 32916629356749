import { UsuarioService } from './../../services/usuario.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [ UsuarioService ]
})
export class HeaderComponent {

  public infoUser;
  
  constructor( private usuarioService: UsuarioService, private router: Router) {
    this.infoUser = this.usuarioService.getIdentity();
  }

  logout() {
    this.usuarioService.logout();
    this.router.navigateByUrl('/login');
  }

}
