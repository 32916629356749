import { Component, OnInit } from '@angular/core';

import { sendEmail } from './../../models/sendEmail.model';
import { EmailService } from './../../services/email.service';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {
  public sendEmail: sendEmail;

    // pluginsEnabled: ['image', 'link'],

  public options: Object = {
    placeholderText: 'Escriba su mensaje...',
    charCounterCount: true,
    attribution: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'undo', 'redo'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'undo', 'redo'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', '-', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'undo', 'redo'],
  };

  constructor(
    public emailServide: EmailService,
    private router: Router
  ) {
    this.sendEmail = new sendEmail(this.emailServide.email, this.emailServide.caso, this.emailServide.setFron, this.emailServide.header + this.emailServide.body + this.emailServide.footer, this.emailServide.nombre);
  }

  ngOnInit(): void {
  }

  enviarCorreo(form) {
    Swal.fire({
      title: '¿Enviar Correo?',
      text: "Precione el botón de continuar si esta listo para enviar el correo electronico.",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enviar',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          onBeforeOpen: () => {
            Swal.showLoading()
          },
          title: 'Enviando',
          text: "Por favor espere...",
          allowOutsideClick: false,
          showConfirmButton: false
        });
        this.emailServide.enviarCorreo(this.sendEmail).subscribe(
          response => {
            if (!response.error) {
              let timerInterval
              Swal.fire({
                icon: 'success',
                title: 'Enviado',
                text: "El correo ha sido enviado correctamente.",
                timer: 1000,
                timerProgressBar: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                  this.router.navigateByUrl('/prospecto');
                }
              })
            } else {
              Swal.fire('Error', 'No se ha podido enviar el correo electronico, intente de nuevo.', 'error');
            }
          },
          error => {
            Swal.fire('Error', 'Ocurrio un error, intente enviar de nuevo el correo.', 'error');
            console.log(<any>error);
          }
        );
      }
    });
  }
}
