import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//Extras
import { environment } from './../../environments/environment.prod';
import { UsuarioService } from '../services/usuario.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(
    private _http: HttpClient,
    private usuarioService: UsuarioService,
  ) { }

  getServicios(): Observable<any> {
    let token = this.usuarioService.getToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.get(base_url + '/admin/navbar', { headers: headers });
  }

  deleteServicio(data): Observable<any> {
    let token = this.usuarioService.getToken();
    let json = JSON.stringify(data);
    let params = 'json=' + json;
    
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.post(base_url + '/admin/elim/service', params, { headers: headers });
  }

  addServicio(form): Observable<any> {
    let token = this.usuarioService.getToken();
    let json = JSON.stringify(form);
    let params = "json=" + json;

    let headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Authorization', 'Bearer ' + token);

    return this._http.post(base_url + '/admin/new/service', params, { headers: headers });
  }

  updateServicio(form): Observable<any> {
    let token = this.usuarioService.getToken();
    let json = JSON.stringify(form);
    let params = "json=" + json;

    let headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Authorization', 'Bearer ' + token);

    return this._http.post(base_url + '/admin/update/service', params, { headers: headers });
  }
}
