import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './../guards/auth.guard';

// Componentes
import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProspectosComponent } from './prospectos/prospectos.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { EmailsComponent } from './emails/emails.component';
import { ServiciosComponent } from './servicios/servicios.component';

const routes: Routes = [
    { 
        path: 'dashboard', 
        component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', component: DashboardComponent },
            { path: 'configuracion', component: AccountSettingsComponent },
        ]
    },
    { 
        path: 'prospecto', 
        component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', component: ProspectosComponent },
            { path: 'email', component: EmailsComponent },
        ]
    },
    { 
        path: 'admin', 
        component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: '', component: ServiciosComponent },
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class PagesRoutingModule {}


