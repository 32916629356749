import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  public base_url: string;

  // Definir los datos del usuario:
  public caso: string = 'Sin caso';
  public email: string = 'Sin correo';
  public nombre: string = 'Sin nombre';
  public setFron: string = 'Sin setFron';

  public header;
  public body;
  public footer;

  constructor(
    private _http: HttpClient,
    private usuarioService: UsuarioService
  ) {
    this.base_url = environment.base_url;
  }

  getTemplates(datos): Observable<any> {
    let token = this.usuarioService.getToken();
    let json = JSON.stringify(datos);
    let params = 'json=' + json;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.post(this.base_url + '/admin/pros/email', params, { headers: headers });
  }

  enviarCorreo(email): Observable<any> {
    let token = this.usuarioService.getToken();
    let json = JSON.stringify(email);
    let params = 'json=' + json;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.post(this.base_url + '/admin/pros/email/send', params, { headers: headers });
  }
}
