import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modulos
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule  } from "@angular/common/http";

// Componentes
import { LoginComponent } from './login/login.component';
import { ValidacionComponent } from './validacion/validacion.component';
import { DatosValidadosComponent } from './datos-validados/datos-validados.component';

@NgModule({
  declarations: [
    LoginComponent,
    ValidacionComponent,
    DatosValidadosComponent
  ],
  exports: [
    LoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ]
})
export class AuthModule { }
