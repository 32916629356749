import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsuarioService } from './usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';

import Swal from 'sweetalert2';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public urls = this.Urls();
  public array = {};

  constructor(
    private _http: HttpClient,
    private usuarioService: UsuarioService,
  ) 
  {
    this.urls = this.Urls();
  }

  menuServicios: any[] = [
    {
      titulo: 'Administración',
      icono: 'fa fa-gear',
      submenu: [
        {titulo: 'Servicios', url: '/admin'}
      ]
    },
  ]

  menu: any[] = [
    {
      titulo: 'Servicios / Productos',
      icono: 'mdi-account-box',
      submenu: this.urls
    },
  ];

  getUrls(): Observable<any> {
    let token = this.usuarioService.getToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.get(base_url + '/admin/navbar', { headers: headers })
  }

  Urls() {
    this.getUrls().subscribe(
      res => {
        this.urls = res.urls;
        this.menu[0].submenu = this.urls;
      }, error => {
        Swal.fire('Error', 'No se ha podido recuperar los servicios existentes.', 'error');
      }
    );
  }

  getNombre(id, name) {
    this.array = {id, name}
  }

}
