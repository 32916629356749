import { tap } from 'rxjs/operators';
import { UsuarioService } from './../services/usuario.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private usuarioService: UsuarioService, private router: Router){}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var log = this.usuarioService.validarLogeado();
    if (log) {
      return true;
    } else {
      this.router.navigateByUrl('/dashboard');
      return this.usuarioService.validarLogeado();
    }
  }
  
}
