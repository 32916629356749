import { UsuarioService } from './../../services/usuario.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  public formSubmitted = false;

  public loginForm = this.fb.group({
    usuario: ['', [Validators.required]],
    password: ['', Validators.required],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private usuarioService: UsuarioService
  ) {}

  login() {
    this.usuarioService.login(this.loginForm.value).subscribe(
      (res) => {
        this.router.navigateByUrl('/dashboard');
      },
      (err) => {
        Swal.fire('Error', 'Usuario y/o contraseñá incorrectos.', 'error');
      }
    );
  }
}
