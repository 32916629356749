export class sendEmail {
    constructor(
        public email: string,
        public subject: string,
        public setFron: string,
        public template: string,
        public nombre: string,
        public header ?: string,
        public body ?: string,
        public footer ?: string
    ){}
}