import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { LoginGuard } from './../guards/login.guard';

// Componentes
import { LoginComponent } from './login/login.component';
import { ValidacionComponent } from './validacion/validacion.component';
import { DatosValidadosComponent } from './datos-validados/datos-validados.component';

const routes: Routes = [
    { path: 'login', canActivate: [ LoginGuard ], component: LoginComponent },
    { path: 'validacion', canActivate: [ LoginGuard ], component: ValidacionComponent },
    { path: 'contacto-validado', canActivate: [ LoginGuard ], component: DatosValidadosComponent },
];

@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
export class AuthRoutingModule {}
