import { Router } from '@angular/router';
import { SidebarService } from '../../services/sidebar.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Component, EventEmitter, OnInit, Output  } from '@angular/core';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [],
  providers: [ UsuarioService ]
})
export class SidebarComponent implements OnInit {
  @Output() nombre: EventEmitter<string> = new EventEmitter<string>();

  public menuItems: any;
  public menuServicios: any;
  public filter;
  public infoUser;

  constructor( private sidebarService: SidebarService, private usuarioService: UsuarioService, private router: Router) {
    this.menuItems = sidebarService.menu;
    this.menuServicios = sidebarService.menuServicios;
    this.infoUser = this.usuarioService.getIdentity();
  }

   filterService(){
    this.menuItems.forEach(element => {
      this.menuItems.submenu = element.submenu.filter( item => {
        return item.titulo.toLowerCase().match(this.filter.toLowerCase());
      });
      console.log(this.menuItems);
    });
  }

  logout() {
    this.usuarioService.logout();
    this.router.navigateByUrl('/login');
  }

  onClick(id, nombre){
    this.sidebarService.getNombre(id,nombre);
  }

  envioNombre(nombre: string){
    this.nombre.emit(nombre);
  }
  
  ngOnInit(): void {
  }

}
