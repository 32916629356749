import { Validacion, ReenvioSMS, CambioTelefono } from './../../models/validacion.model';
import { ValidacionService } from "../../services/validacion.service";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import $ from "jquery";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-validacion',
  templateUrl: './validacion.component.html',
  styleUrls: ['./validacion.component.css'],
  providers: [ValidacionService]
})
export class ValidacionComponent implements OnInit {
  public validacion: Validacion;
  public reenvio: ReenvioSMS;
  public telNuevo: CambioTelefono;
  public urlTree;

  constructor(
    private router: Router,
    private _validacionService: ValidacionService,
  ) {
    this.urlTree = this.router.parseUrl(this.router.url);
    
    this.validacion = new Validacion('');
    this.telNuevo = new CambioTelefono('','',this.urlTree.queryParams['tel']);
    this.reenvio = new ReenvioSMS(this.urlTree.queryParams['tel']);
  }

  ngOnInit(): void {
  }

  onSubmit(form) {
    this._validacionService.validarTelefono(this.validacion).subscribe(
      response => {
        if (!response.error) {
          this.router.navigateByUrl('/contacto-validado');
        } else {
          Swal.fire('Código Incorrecto', response.message, 'error');
          console.log(response);
        }
      },
      error => {
        Swal.fire('Error', 'El número de verificaión es incorrecto, verifique su número.', 'error');
        console.log(<any>error);
      }
    );
  }

  smsReenvio(form) {
    this.urlTree = this.router.parseUrl(this.router.url);
    this.reenvio.telefono = this.urlTree.queryParams['tel'];
    
    this._validacionService.reenviarSMS(this.reenvio).subscribe(
      response => {
        if (!response.error) {
          let timerInterval
          Swal.fire({
            icon: 'success',
            title: 'Enviado!',
            text: "Se ha enviado otro código de verificación a su teléfono, por favor ingreselo.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          });
        } else {
          Swal.fire('Error', 'Ocurrio un error, intente de nuevo.', 'error');
        }
      },
      error => {
        Swal.fire('Error', 'No se ha podido reenviar el código de verificación.', 'error');
        console.log(<any>error);
      }
    );
  }

  telefonoNuevo(form){
    $('#exampleModalCenter').hide();
    $('.modal-backdrop').hide();
    this._validacionService.cambioTelefono(this.telNuevo).subscribe(
      response => {
        console.log(response);
        if (!response.error) {
          let timerInterval
          Swal.fire({
            icon: 'success',
            title: 'Enviado!',
            text: "Se ha enviado otro código de verificación a su teléfono, por favor ingreselo.",
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval);
              this.router.navigateByUrl('/validacion?tel='+response.message);
              form.reset();
            }
          });
        } else {
          Swal.fire('Error', 'Ocurrio un error, intente de nuevo.', 'error');
          console.log(response);
        }
      },
      error => {
        Swal.fire('Error', 'No se ha podido reenviar el código de verificación.', 'error');
        console.log(error);
      }
    );
  }

}
