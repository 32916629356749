export class Validacion {
    constructor(
        public telToken: string,
    ) { }
}

export class ReenvioSMS {
    constructor(
        public telefono: string,
    ) { }
}

export class CambioTelefono {
    constructor(
        public prefijo: string,
        public telefono: string,
        public tel: string
    ) { }
}

export class ServiciosForm {
    constructor(
        public idNav: number,
        public titulo: string,
        public url: string,
        public idUsuario: number
    ) { }
}
