<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
    <ng-container *ngFor="let tab of tabs; let index = index">
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="col">{{tab}}</div>
                <button class="close" [disabled]="tabs.length === 1" style="color:black" (click)="removeTab(index)">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-template>

            <span [ngSwitch]="tab">
                <div *ngSwitchCase="'ITTEC'">
                    <br>
                    <div class="text-right">
                        <mat-form-field>
                            <mat-label><i class="mdi mdi-magnify"></i> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
                        </mat-form-field>
                    </div>
            
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                            <ng-container matColumnDef="Estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element">
                                    <button *ngIf="element.estatus == 'V'" type="button"
                                        class="btn btn-sm btn-success btn-circle"><i class="fa fa-check"></i></button>
                                    <button *ngIf="element.estatus == 'PV'" type="button"
                                        class="btn btn-sm btn-warning btn-circle"><i class="fa fa-exclamation"></i></button>
                                    <button *ngIf="element.estatus == 'NV'" type="button"
                                        class="btn btn-sm btn-danger btn-circle"><i class="fa fa-times"></i></button>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Cargo">
                                <th mat-header-cell *matHeaderCellDef> Cargo </th>
                                <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Nombre">
                                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let element"> {{element.nombre + ' ' + element.apellido}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Telefono">
                                <th mat-header-cell *matHeaderCellDef> Teléfono </th>
                                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> Email </th>
                                <td mat-cell *matCellDef="let element"> <a href="mailto:{{element.email}}">{{element.email}}</a>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Empresa">
                                <th mat-header-cell *matHeaderCellDef> Empresa </th>
                                <td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                    <div class="example-element-detail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="example-element-description">
                                            <dl>
                                                <dt>{{element.asunto}}:</dt>
                                                <dd>{{element.comentarios}}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Funciones">
                                <th mat-header-cell *matHeaderCellDef> Funciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-danger dropdown-toggle" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-envelope"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item"
                                                (click)="datosEmail(element.email, element.nombre + ' ' + element.apellido, 'seguimiento-ittec', element.asunto)">Seguimiento
                                                de Caso</a>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                [class.example-expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            
                            <tr class="mat-row" *matNoDataRow style="text-align: center;">
                                <td class='mat-cell' colspan="7" *ngIf='input.value != ""'>No existe ningun registro con la
                                    siguiente información: "{{input.value}}"</td>
                                <td class='mat-cell' colspan="7" *ngIf='input.value == ""'>No hay datos disponibles para mostrar.
                                </td>
                            </tr>
                        </table>
            
                        <mat-paginator #pagIttec [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
                <div *ngSwitchCase="'Plataforma Edu'">
                    <div class="text-right">
                        <br>
                        <mat-form-field>
                            <mat-label><i class="mdi mdi-magnify"></i> Buscar</mat-label>
                            <input matInput (keyup)="applyFilterEdu($event)" placeholder="Buscar" #inputEdu>
                        </mat-form-field>
                    </div>
            
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSourceEdu" multiTemplateDataRows matSort>
                            <ng-container matColumnDef="Estado">
                                <th mat-header-cell *matHeaderCellDef> Estado </th>
                                <td mat-cell *matCellDef="let element">
                                    <button *ngIf="element.estatus == 'V'" type="button"
                                        class="btn btn-sm btn-success btn-circle"><i class="fa fa-check"></i></button>
                                    <button *ngIf="element.estatus == 'PV'" type="button"
                                        class="btn btn-sm btn-warning btn-circle"><i class="fa fa-exclamation"></i></button>
                                    <button *ngIf="element.estatus == 'NV'" type="button"
                                        class="btn btn-sm btn-danger btn-circle"><i class="fa fa-times"></i></button>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="InsEducativa">
                                <th mat-header-cell *matHeaderCellDef> Institución </th>
                                <td mat-cell *matCellDef="let element"> {{element.insEducativa}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Cargo">
                                <th mat-header-cell *matHeaderCellDef> Cargo </th>
                                <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Nombre">
                                <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                <td mat-cell *matCellDef="let element"> {{element.nombre + ' ' + element.apellido}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Telefono">
                                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Email">
                                <th mat-header-cell *matHeaderCellDef> Email </th>
                                <td mat-cell *matCellDef="let element"> <a href="mailto:{{element.email}}">{{element.email}}</a>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayEdu.length">
                                    <div class="example-element-detail"
                                        [@detailExpand]="element == expandedElementEdu ? 'expanded' : 'collapsed'">
                                        <div class="example-element-description">
                                            <dl>
                                                <dt>Ubicación:</dt>
                                                <dd>{{element.direccion}}</dd>
                                            </dl>
                                        </div>
                                        <div class="example-element-description">
                                            <dl>
                                                <dt>{{element.asunto}}:</dt>
                                                <dd>{{element.comentarios}}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <ng-container matColumnDef="Funciones">
                                <th mat-header-cell *matHeaderCellDef> Funciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-danger dropdown-toggle" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-envelope"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item"
                                                (click)="datosEmail(element.email, element.nombre + ' ' + element.apellido, 'seguimiento-plataforma', element.asunto)">Seguimiento
                                                de Caso</a>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
            
                            <tr mat-header-row *matHeaderRowDef="columnsToDisplayEdu"></tr>
                            <tr mat-row *matRowDef="let element; columns: columnsToDisplayEdu;" class="example-element-row"
                                [class.example-expanded-row]="expandedElementEdu === element"
                                (click)="expandedElementEdu = expandedElementEdu === element ? null : element"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            
                            <tr class="mat-row" *matNoDataRow style="text-align: center;">
                                <td class='mat-cell' colspan="7" *ngIf='inputEdu.value != ""'>No existe ningun registro con la
                                    siguiente información: "{{inputEdu.value}}"</td>
                                <td class='mat-cell' colspan="7" *ngIf='inputEdu.value == ""'>No hay datos disponibles para mostrar.
                                </td>
                            </tr>
                        </table>
            
                        <mat-paginator #pagEdu [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </span>

        </mat-tab>
    </ng-container>
</mat-tab-group>

<!-- <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#ittec" role="tab"
            aria-expanded="true"><span class="hidden-sm-up"><i class="ti-home"></i></span> <span
                class="hidden-xs-down">ITTEC &nbsp; <span class="label label-light-danger"> {{dataSource.data.length}}
                </span></span></a> </li>
    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#plaEdu" role="tab" aria-expanded="false"><span
                class="hidden-sm-up"><i class="ti-user"></i></span> <span class="hidden-xs-down">Plataforma Edu &nbsp;
                <span class="label label-light-danger"> {{dataSourceEdu.data.length}} </span></span></a> </li>
</ul>
<div class="tab-content tabcontent-border">
    <div class="tab-pane p-30 active" id="ittec" role="tabpanel" aria-expanded="true">
        <div class="text-right">
            <mat-form-field>
                <mat-label><i class="mdi mdi-magnify"></i> Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
            </mat-form-field>
        </div>

        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="Estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="element.estatus == 'V'" type="button"
                            class="btn btn-sm btn-success btn-circle"><i class="fa fa-check"></i></button>
                        <button *ngIf="element.estatus == 'PV'" type="button"
                            class="btn btn-sm btn-warning btn-circle"><i class="fa fa-exclamation"></i></button>
                        <button *ngIf="element.estatus == 'NV'" type="button"
                            class="btn btn-sm btn-danger btn-circle"><i class="fa fa-times"></i></button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Cargo">
                    <th mat-header-cell *matHeaderCellDef> Cargo </th>
                    <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
                </ng-container>

                <ng-container matColumnDef="Nombre">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre + ' ' + element.apellido}} </td>
                </ng-container>

                <ng-container matColumnDef="Telefono">
                    <th mat-header-cell *matHeaderCellDef> Teléfono </th>
                    <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> <a href="mailto:{{element.email}}">{{element.email}}</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Empresa">
                    <th mat-header-cell *matHeaderCellDef> Empresa </th>
                    <td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="example-element-description">
                                <dl>
                                    <dt>{{element.asunto}}:</dt>
                                    <dd>{{element.comentarios}}</dd>
                                </dl>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Funciones">
                    <th mat-header-cell *matHeaderCellDef> Funciones </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-danger dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-envelope"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item"
                                    (click)="datosEmail(element.email, element.nombre + ' ' + element.apellido, 'seguimiento-ittec', element.asunto)">Seguimiento
                                    de Caso</a>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                <tr class="mat-row" *matNoDataRow style="text-align: center;">
                    <td class='mat-cell' colspan="7" *ngIf='input.value != ""'>No existe ningun registro con la
                        siguiente información: "{{input.value}}"</td>
                    <td class='mat-cell' colspan="7" *ngIf='input.value == ""'>No hay datos disponibles para mostrar.
                    </td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div class="tab-pane p-30" id="plaEdu" role="tabpanel" aria-expanded="false">
        <div class="text-right">
            <mat-form-field>
                <mat-label><i class="mdi mdi-magnify"></i> Buscar</mat-label>
                <input matInput (keyup)="applyFilterEdu($event)" placeholder="Buscar" #inputEdu>
            </mat-form-field>
        </div>

        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceEdu" multiTemplateDataRows matSort>
                <ng-container matColumnDef="Estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="element.estatus == 'V'" type="button"
                            class="btn btn-sm btn-success btn-circle"><i class="fa fa-check"></i></button>
                        <button *ngIf="element.estatus == 'PV'" type="button"
                            class="btn btn-sm btn-warning btn-circle"><i class="fa fa-exclamation"></i></button>
                        <button *ngIf="element.estatus == 'NV'" type="button"
                            class="btn btn-sm btn-danger btn-circle"><i class="fa fa-times"></i></button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="InsEducativa">
                    <th mat-header-cell *matHeaderCellDef> Institución </th>
                    <td mat-cell *matCellDef="let element"> {{element.insEducativa}} </td>
                </ng-container>

                <ng-container matColumnDef="Cargo">
                    <th mat-header-cell *matHeaderCellDef> Cargo </th>
                    <td mat-cell *matCellDef="let element"> {{element.cargo}} </td>
                </ng-container>

                <ng-container matColumnDef="Nombre">
                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre + ' ' + element.apellido}} </td>
                </ng-container>

                <ng-container matColumnDef="Telefono">
                    <th mat-header-cell *matHeaderCellDef> Telefono </th>
                    <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> <a href="mailto:{{element.email}}">{{element.email}}</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayEdu.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElementEdu ? 'expanded' : 'collapsed'">
                            <div class="example-element-description">
                                <dl>
                                    <dt>Ubicación:</dt>
                                    <dd>{{element.direccion}}</dd>
                                </dl>
                            </div>
                            <div class="example-element-description">
                                <dl>
                                    <dt>{{element.asunto}}:</dt>
                                    <dd>{{element.comentarios}}</dd>
                                </dl>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Funciones">
                    <th mat-header-cell *matHeaderCellDef> Funciones </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-danger dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-envelope"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item"
                                    (click)="datosEmail(element.email, element.nombre + ' ' + element.apellido, 'seguimiento-plataforma', element.asunto)">Seguimiento
                                    de Caso</a>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayEdu"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplayEdu;" class="example-element-row"
                    [class.example-expanded-row]="expandedElementEdu === element"
                    (click)="expandedElementEdu = expandedElementEdu === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                <tr class="mat-row" *matNoDataRow style="text-align: center;">
                    <td class='mat-cell' colspan="7" *ngIf='inputEdu.value != ""'>No existe ningun registro con la
                        siguiente información: "{{inputEdu.value}}"</td>
                    <td class='mat-cell' colspan="7" *ngIf='inputEdu.value == ""'>No hay datos disponibles para mostrar.
                    </td>
                </tr>
            </table>

            <mat-paginator #pagEdu [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div> -->