import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

//Extras
import { environment } from './../../environments/environment.prod';
import { UsuarioService } from '../services/usuario.service';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root',
})
export class ProspectoService {
  constructor(
    private _http: HttpClient,
    private usuarioService: UsuarioService,
  ) {}

  getProspectos(): Observable<any> {
    let token = this.usuarioService.getToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + token);

    return this._http.get(base_url + '/admin/prospectos', { headers: headers })
  }

}
