<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img
                            src="./assets/images/picProfile.png" alt="user" /><span class="hide-menu">{{ infoUser.nombre }} {{ infoUser.apellido }}
                        </span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mi Perfil</a></li>
                        <li><a routerLink="/dashboard/configuracion" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Configuración</a></li>
                        <li><a (click)="logout()" class="cursor" routerLink="/login" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Cerrar Sesión</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">MENÚ</li>
                <!-- <input (ngModelChange)="filterService()" [(ngModel)]="filter" type="text"> -->
                <li *ngFor="let item of menuServicios">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i class="fa {{ item.icono }}"></i>
                        <span class="hide-menu">
                            {{ item.titulo }}
                        </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let menuServicios of item.submenu">
                            <a [routerLink]="[menuServicios.url]"> {{ menuServicios.titulo }} </a>
                        </li>
                    </ul>
                </li>
                <li *ngFor="let item of menuItems" class="active">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="true">
                        <i class="mdi {{ item.icono }}"></i>
                        <span class="hide-menu">
                            {{ item.titulo }}
                        </span>
                    </a>
                    <ul aria-expanded="true" class="collapse">
                        <li *ngFor="let subMenuItem of item.submenu">
                            <a *ngIf="subMenuItem.estado == 'Activo'" [routerLink]="[subMenuItem.url]" [queryParams]="{tab: subMenuItem.titulo}" (click)="envioNombre($event.target.value)"> {{ subMenuItem.titulo }} </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->