<div id="main-wrapper">

    <app-header></app-header>
    <app-sidebar (nombre)="nombre = $event"></app-sidebar>

    <div class="page-wrapper">
        <div class="container-fluid">
            <!-- Breadcrumbs -->
            <!-- <app-breadcrumbs></app-breadcrumbs> -->

            <!-- Mi sistema de rutas -->
            <router-outlet></router-outlet>

            <!-- 
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            
                        </div>
                    </div>
                </div>
            </div> 
            -->
        </div>

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer">
            Copyright © 2021 ITTEC. Todos los derechos reservados.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
</div>