import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';

// Mat Tables
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Servicios
import { ProspectoService } from './../../services/prospecto.service';
import { EmailService } from './../../services/email.service';

//Alertas
import Swal from 'sweetalert2';

@Component({
  selector: 'app-prospectos',
  styleUrls: ['./prospectos.component.css'],
  templateUrl: './prospectos.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProspectosComponent implements OnInit, AfterViewInit {
  // Datos para las tablas
  public columnsToDisplay: string[] = ['Estado', 'Empresa', 'Cargo', 'Nombre', 'Telefono', 'Email', 'Funciones'];
  public dataSource = new MatTableDataSource();
  public expandedElement: ProspectosIttec | null;

  public columnsToDisplayEdu: string[] = ['Estado', 'InsEducativa', 'Cargo', 'Nombre', 'Telefono', 'Email', 'Funciones'];
  public dataSourceEdu = new MatTableDataSource();
  public expandedElementEdu: ProspectosEdu | null;

  // Páginador Tabla
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('pagIttec') paginator: MatPaginator;
  @ViewChild('pagEdu') paginatorEdu: MatPaginator;

  // Datos para envio de correo
  public email: string;
  public nombre: string;

  // Contenido para las tabs
  tabs = [];
  selected = new FormControl(0);
  @Input() nombreServicio: string;

  constructor(
    private prospectoService: ProspectoService,
    private router: Router,
    private emailService: EmailService,
    private actRoute: ActivatedRoute
  ) {
    this.actRoute.queryParams.subscribe(params => {
      if (params['tab'] != null) {
        if ((this.tabs.indexOf(params['tab'])) < 0) {
          this.addTab(params['tab']);

          setTimeout(() => {
            this.ngAfterViewInit();
          }, 100);
        }
      }

      // Limpiar la URL
      this.router.navigate(['/prospecto'], { queryParams: { tab: null }, queryParamsHandling: 'merge' });
    });
  }

  ngOnInit() {
    this.getProspectos();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    this.dataSourceEdu.paginator = this.paginatorEdu;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilterEdu(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEdu.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceEdu.paginator) {
      this.dataSourceEdu.paginator.firstPage();
    }
  }

  addTab(title) {
    this.tabs.push(title);
    this.selected.setValue(this.tabs.length - 1);
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
    this.router.navigate(['/prospecto'], { queryParams: { tab: null }, queryParamsHandling: 'merge' });
  }

  getProspectos() {
    this.prospectoService.getProspectos().subscribe(
      res => {
        this.dataSource.data = res.pros_ITTEC;
        this.dataSourceEdu.data = res.pros_pEdu;
      }, error => {
        Swal.fire('Error', 'No se ha podido recuperar prospectos existentes.', 'error');
      }
    );
  }

  datosEmail(email, nombre, tipo, caso) {
    let json = {
      "tipo": tipo,
      "nombre": nombre,
      "caso": caso
    }

    this.emailService.getTemplates(json).subscribe(
      response => {
        if (!response.error) {
          this.emailService.email = email;
          this.emailService.nombre = nombre;
          this.emailService.caso = response.template.subject;
          this.emailService.setFron = response.template.setFron;

          // Armar el correo:
          this.emailService.header = response.template.header;
          this.emailService.body = response.template.body;
          this.emailService.footer = response.template.footer;

          this.router.navigateByUrl('/prospecto/email');
        } else {
          Swal.fire('Error Plantilla', response.message, 'error');
        }
      },
      error => {
        Swal.fire('Error', 'No se pudo cargar la información necesaria.', 'error');
      }
    );
  }
}

export interface ProspectosIttec {
  id: number;
  nombre: string;
  apellido: string;
  cargo: string;
  telefono: string;
  email: string;
  estatus: string;
  idIttec: number;
  asunto: string;
  empresa: string;
  comentarios: string;
  lead: number;
}

export interface ProspectosEdu {
  id: number;
  nombre: string;
  apellido: string;
  cargo: string;
  telefono: string;
  email: string;
  estatus: string;
  idEdu: number;
  asunto: string;
  insEducativa: string;
  ciudad: string;
  direccion: string;
  cp: number;
  comentarios: string;
  lead: number;
}
