import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ValidacionService {
    
    public base_url: string;

    constructor(
        private _http: HttpClient,
    ) {
        this.base_url = environment.base_url;
    }

    validarTelefono(validacion): Observable<any> {
        let json = JSON.stringify(validacion);
        let params = "json=" + json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.base_url + '/confirm/tel', params, {headers: headers});
    }

    reenviarSMS(reenvio):Observable<any> { 
        let json = JSON.stringify(reenvio);
        let params = "json=" + json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.base_url + '/confirm/sms', params, {headers: headers});
    }

    cambioTelefono(nuevoTelefono):Observable<any> { 
        let json = JSON.stringify(nuevoTelefono);
        let params = "json=" + json;

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.base_url + '/confirm/telSec', params, {headers: headers});
    }
}
