import { UsuarioService } from './../services/usuario.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private usuarioService: UsuarioService, private router: Router) {}

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var log = this.usuarioService.validarToken();
    if (log) {
      // console.log('entraste')
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return this.usuarioService.validarToken();
    }
  }
}
