import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { loginForm } from './../interfaces/login-form.interface';
import { environment } from '../../environments/environment';
import { Usuario } from './../models/usuario.model';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  public usuario: Usuario;
  public identity;
  public token;

  constructor(private http: HttpClient) {}

  validarToken() {
    const token = localStorage.getItem('token') || null;
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  validarLogeado() {
    const token = localStorage.getItem('token') || null;
    if (token) {
      return false;
    } else {
      return true;
    }
  }

  login(formData: loginForm) {
    let json = JSON.stringify(formData);
    let params = "json=" + json;

    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this.http
      .post(`${base_url}/login`, params, { headers: headers })
      .pipe(
        tap((res: any) => {
          console.log(res);
          let { apellido, idUsuario, nombre, token, usuario } = res.result;
          this.usuario = new Usuario(idUsuario, usuario, '', nombre, apellido);
          localStorage.setItem('token', token);
          localStorage.setItem('identity', JSON.stringify(this.usuario));
        })
      );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('identity');
  }

  getIdentity(){
    let identity = JSON.parse(localStorage.getItem('identity'));
    if (identity && identity != 'undefined'){
      this.identity = identity;
    } else {
      this.identity = null;
    }

    return this.identity;
  }

  getToken(){
    let token = localStorage.getItem('token');
    if (token && token != 'undefined'){
      this.token = token;
    } else {
      this.token = null;
    }

    return this.token;
  }
}
