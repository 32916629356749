<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper">
    <div class="login-register" style="background-color:#F3F3F3;">
        <div class="login-box card card-signin"><br>
            <div class="card-body">
                <img src="./assets/images/logo_ittec.png" alt="ITTEC" width="255px" height="110px">
                <br><br>
                <form class="form-horizontal form-material" autocomplete="off" [formGroup]="loginForm"
                    (ngSubmit)="login()" id="loginform">
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" placeholder="Usuario" formControlName="usuario" required>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control" type="password" placeholder="Contraseña"
                                formControlName="password" required>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                            <button class="btn btn-block btn-blcok btn-info btn-rounded" type="submit">Iniciar
                                Sesión</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->