<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form #correoForm="ngForm" (ngSubmit)="enviarCorreo(correoForm)">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon">Asunto:</div>
                            <input class="form-control" #subject="ngModel" name="subject" [(ngModel)]=sendEmail.subject
                                value="{{emailServide.caso}}">
                            <input hidden class="form-control" #setFron="ngModel" name="setFron"
                                [(ngModel)]=sendEmail.setFron value="{{emailServide.setFron}}">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-addon"> &nbsp; Para: &nbsp;</div>
                            <input hidden type="text" class="form-control" #email="ngModel" name="email"
                                [(ngModel)]=sendEmail.email value="{{emailServide.email}}">
                            <input type="text" class="form-control" #nombre="ngModel" name="nombre"
                                [(ngModel)]=sendEmail.nombre value="{{emailServide.nombre}}">
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea #template="ngModel" name="template" [(ngModel)]=sendEmail.template [froalaEditor]="options">
                            {{emailServide.body}}
                        </textarea>
                    </div>
                    <div class="form-group" style="text-align: right;">
                        <input type="submit" value="Enviar" class="btn btn-success waves-effect waves-light m-r-10">
                        <a class="btn btn-danger waves-effect waves-light" href="/prospecto">Cancelar</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
