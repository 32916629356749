<div class="row">
    <div class="col-md-6 align-self-center">
        <h3 class="text-themecolor">Gestión de Servicios</h3>
    </div>
    
    <div class="col-md-6 align-self-center text-right">
        <button type="button" class="btn btn-success" data-toggle="modal" data-target="#exampleModal" data-whatever="@fat">Registrar Servicio</button>
    </div>
</div>

<br>

<div class="row">
    <div class="col-md-1 align-self-center">
    </div>
    <div class="col-md-10 align-self-center">
        <div class="text-right">
            <mat-form-field>
                <mat-label><i class="mdi mdi-magnify"></i> Buscar</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" #input>
            </mat-form-field>
        </div>
        
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
        
                <!-- ID Column -->
                <ng-container matColumnDef="No">
                    <th mat-header-cell *matHeaderCellDef> No. </th>
                    <td mat-cell *matCellDef="let i = index"> {{i + 1}} </td>
                </ng-container>
        
                <!-- Progress Column -->
                <ng-container matColumnDef="Servicio">
                    <th mat-header-cell *matHeaderCellDef> Servicio </th>
                    <td mat-cell *matCellDef="let row"> {{row.titulo}} </td>
                </ng-container>
        
                <!-- Name Column -->
                <ng-container matColumnDef="Estado">
                    <th mat-header-cell *matHeaderCellDef> Estado </th>
                    <td mat-cell *matCellDef="let row">
                        <button (click)="deleteService(row.idNav, 'Eliminado')" *ngIf="row.estado == 'Activo'" type="button"
                            class="btn btn-sm btn-success btn-circle"><i class="fa fa-check"></i></button>
                        <button (click)="deleteService(row.idNav, 'Activo')" *ngIf="row.estado == 'Eliminado'" type="button"
                            class="btn btn-sm btn-danger btn-circle"><i class="fa fa-times"></i></button>
                    </td>
                </ng-container>
        
                <!-- Name Column -->
                <ng-container matColumnDef="Funciones">
                    <th mat-header-cell *matHeaderCellDef> Funciones </th>
                    <td mat-cell *matCellDef="let row">
                        <button type="button" class="btn btn-warning" data-toggle="modal" data-target="#modificarModal" (click)="modificarForm(row)" data-whatever="@fat">Modificar</button>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class='mat-cell' colspan="3" *ngIf='input.value != ""'>No existe ningun registro con la siguiente
                        información: "{{input.value}}"</td>
                    <td class='mat-cell' colspan="3" *ngIf='input.value == ""'>No hay datos disponibles para mostrar.</td>
                </tr>
            </table>
        
            <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
        </div>
    </div>
    <div class="col-md-1 align-self-center">
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel1">Registro Servicio</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <form #nuevoServicio="ngForm" (ngSubmit)="addService(nuevoServicio)">
                    <div class="form-group">
                        <label for="titulo" class="control-label">Nombre:</label>
                        <input required name="titulo" #titulo="ngModel" [(ngModel)]="servicio.titulo" type="text" class="form-control" id="titulo">
                    </div>
                    <hr>
                    <div class="col-md-12 text-right">
                        <input type="submit" value="Registrar" class="btn btn-success" [disabled]="nuevoServicio.invalid">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modificarModal" tabindex="-1" role="dialog" aria-labelledby="modificarModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="modificarModal">Registro Servicio</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <form #modServicio="ngForm" (ngSubmit)="updateService(modServicio)">
                    <div class="form-group">
                        <label for="titulo" class="control-label">Nombre:</label>
                        <input required name="titulo" #titulo="ngModel" [(ngModel)]="servicioMod.titulo" type="text" class="form-control" id="titulo">
                    </div>
                    <hr>
                    <div class="col-md-12 text-right">
                        <input type="submit" value="Modificar" class="btn btn-warning" [disabled]="modServicio.invalid">
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>