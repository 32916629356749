import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ServiciosForm } from './../../models/validacion.model';

// Mat Tables
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// Servicios
import { ServiciosService } from './../../services/servicios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';

//Alertas
import Swal from 'sweetalert2';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit, AfterViewInit {

  public columnsToDisplay: string[] = ['No', 'Servicio', 'Estado', 'Funciones'];
  public dataSource = new MatTableDataSource();

  @ViewChild('servicios') paginator: MatPaginator;

  public servicio: ServiciosForm;
  public servicioMod: ServiciosForm;
  public idUsuario;
  public formModificar;

  constructor(
    private servicioService: ServiciosService,
    private usuarioService: UsuarioService,
    private router: Router,
    private actRoute: ActivatedRoute
  ) {
    this.idUsuario = usuarioService.getIdentity();
    this.servicio = new ServiciosForm(1, '', '/prospecto', this.idUsuario.idUsuario);
    this.servicioMod = new ServiciosForm(1, '', '/prospecto', this.idUsuario.idUsuario);
  }

  ngOnInit() {
    this.getServicios();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  modificarForm(obj){
    this.servicioMod.idNav = obj.idNav;
    this.servicioMod.titulo = obj.titulo;
    console.log(this.servicioMod);
  }

  updateService(form){
    this.servicioService.updateServicio(this.servicioMod).subscribe(
      response => {
        if (!response.error) {
          Swal.fire('Modificado', 'Se ha modificado correctamente el servicio.', 'success');
          this.getServicios();
          location.reload();
        } else {
          Swal.fire('Error', response.message , 'error');
        }
      },
      error => {
        Swal.fire('Error', 'No se ha podido modificar el servicio, intentelo de nuevo.', 'error');
        console.log(<any>error);
      }
    );
  }
  
  getServicios() {
    this.servicioService.getServicios().subscribe(
      res => {
        this.dataSource.data = res.urls;
      }, error => {
        Swal.fire('Error', 'No se ha podido recuperar los servicios existentes.', 'error');
      }
    );
  }

  deleteService(idNav, estado) {
    let identity = this.usuarioService.getIdentity();
    let json = {
      "idNav": idNav,
      "estado": estado,
      "idUsuario": identity.idUsuario
    }

    this.servicioService.deleteServicio(json).subscribe(
      response => {
        if (!response.error) {
          location.reload();
          if (estado == 'Activo') {
            Swal.fire('Activo', 'Se ha activado el servicio correctamente.', 'success');
          } else {
            Swal.fire('Desactivado', 'Se ha desactivado el servicio correctamente.', 'success');
          }
          this.getServicios();
          location.reload();
        } else {
          Swal.fire('Error', response.message, 'error');
        }
      },
      error => {
        Swal.fire('Error', 'No se pudo cargar la información necesaria.', 'error');
        console.log(<any>error);
      }
    );
  }

  addService(form) {
    this.servicioService.addServicio(this.servicio).subscribe(
      response => {
        if (!response.error) {
          Swal.fire('Registrado', 'Se ha registrado correctamente el servicio.', 'success');
          this.getServicios();
        } else {
          Swal.fire('Error', response.message , 'error');
        }
      },
      error => {
        Swal.fire('Error', 'No se ha podido registrar el servicio, intentelo de nuevo.', 'error');
      }
    );
  }

}

export interface Servicios {
  idNav: number;
  titulo: string;
  estado: string;
}

